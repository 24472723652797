import React from 'react';

const MODES = [
  {
    name: "All",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_collection.svg",
  },
  {
    name: "Summoner's Rift",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-classic.svg",
  },
  {
    name: "ARAM",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-aram.svg",
  },
  {
    name: "Co-op vs. AI",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/eternals-personal-best.svg",
  },
];

const SORTS = [
  {
    name: "Challenge Level",
    icon: (
      <svg style={{ height: 20, width: 20, marginRight: 4 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>),
  },
  {
    name: "Total Points",
    icon: (
      <svg style={{ height: 20, width: 20, marginRight: 4 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
  },
  {
    name: "Precentile",
    icon: (
      <svg style={{ height: 20, width: 20, marginRight: 4 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
  },
];


function LowHangingFruitFilter(props) {
  const selectors = props.filterSelectors;

  return (
    <div className={"LHFFilterContainer"}>
      <div className={"LHFFilterSelectors"}>
        <div className={"LHFCategoryHeader"}>
          Game Mode
        </div>
        <div className={"LHFCategory"}>
          {MODES.map(f => (
            <div key={f.name}
              className={'LHFFilterSingleSelector' + (f.name.toLowerCase() === selectors.mode?.toLowerCase() ? " SELECTED" : "")}
              onClick={() => props.onClickSelector({ mode: handleClickOnSelector(selectors.mode, f.name), sort: selectors.sort })}>
              <img className='LHFFilterSelectorIconSVG' src={f.url} alt='' />
              {f.name}
            </div>
          ))}
        </div>
      </div>
      <div className={"LHFFilterSelectors"}>
        <div className={"LHFCategoryHeader"}>
          Sort by
        </div>
        <div className={"LHFCategory"}>
          {SORTS.map(f => (
            <div key={f.name}
              className={'LHFFilterSingleSelector' + (f.name.toLowerCase() === selectors.sort?.toLowerCase() ? " SELECTED" : "")}
              onClick={() => props.onClickSelector({ mode: selectors.mode, category: selectors.category, sort: handleClickOnSelector(selectors.sort, f.name), shownGamers: selectors.shownGamers })}>
              {f.icon}
              {f.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function handleClickOnSelector(existingSelector, clickedSelector) {
  if (existingSelector == null) {
    return clickedSelector;
  } else if (existingSelector.toLowerCase() === clickedSelector.toLowerCase()) {
    return null;
  } else {
    return clickedSelector;
  }
}

export default LowHangingFruitFilter;
