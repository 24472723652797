import Grades from "../Grade.react";
import React from 'react';

function MasteryRowGradeProgress(props: {grades: any, name: string}): React.Element {
  return (
    <div key={props.name} className="tableHovercard">
      <Grades rawGrades={props.grades} />
    </div>
  )
}

export default MasteryRowGradeProgress;
