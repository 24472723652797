import MasterTheEnemyHeaderCard from './MasterTheEnemyHeaderCard.react';
import React from 'react';
import MasterTheEnemyMasteryList from './MasterTheEnemyMasteryList.react';
import {useContext} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function MasterTheEnemyChallengeView(props: Props): React.Element {
  const { challengesData } = useContext(ChallengesDataContext);

  const currentLevel = challengesData.master_the_enemy.current_level;
  const nextLevel = challengesData.master_the_enemy.next_level;
  return (
    <div className={"challengeViewContainer"}>
      <MasterTheEnemyHeaderCard
        currentLevel={currentLevel}
        nextLevel={nextLevel}
        description={challengesData.master_the_enemy.description}
        iconURL={challengesData.master_the_enemy.current_level_icon}/>
      <MasterTheEnemyMasteryList
        summoner={props.lookupTarget.summoner}
        amountToShow={nextLevel.value - (currentLevel?.value ?? 0) + 8}
      />
    </div>
  );
}

export default MasterTheEnemyChallengeView;
