import React, { useEffect } from 'react';
import LeaderboardLeftSidebar from './LeaderboardLeftSidebar.react';
import LeaderboardChallenges from './LeaderboardChallenges.react';
import {useState} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function LeaderboardChallengeSection(props: Props): React.Element {
  const { staticSummonerData } = useContext(ChallengesDataContext);
  const gamers = Object.keys(staticSummonerData?.all_participants ?? {});

  const [filterSelectors, setFilterSelectors] = useState({mode: null, category: null, sort: null, shownGamers: gamers, trivia: false});
  const { leaderboardData } = useContext(ChallengesDataContext);

  const allChallenges = Object.values(leaderboardData ?? {});

  useEffect(() => {
    setFilterSelectors({mode: filterSelectors.mode, category: filterSelectors.category, sort: filterSelectors.sort, shownGamers: gamers, trivia: filterSelectors.trivia});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticSummonerData]);

  return (
    <div className='LeaderboardMainSection'>
      <LeaderboardLeftSidebar filterSelectors={filterSelectors} onClickSelector={setFilterSelectors}/>
      <LeaderboardChallenges 
        challengesToShow={applyFilters(allChallenges, filterSelectors)} 
        lookupTarget={props.lookupTarget} 
        sortBy={filterSelectors.sort} 
        shownGamers={filterSelectors.shownGamers} 
        trivia={filterSelectors.trivia}/>
    </div>
  );
}

function applyFilters(challenges: [], filterSelectors: {mode: ?String, category: ?String, sort: ?String}, summoner: {}): [] {
  return challenges.filter(c => {
    if (filterSelectors.mode != null && c.queue?.toLowerCase() != null && c.queue.toLowerCase() !== filterSelectors.mode.toLowerCase()) {
      return false;
    }
    if (filterSelectors.category != null && c.category?.toLowerCase() && c.category.toLowerCase() !== filterSelectors.category.toLowerCase()) {
      return false;
    }
    return true;
  });
}

export default LeaderboardChallengeSection;
