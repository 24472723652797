import React from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import ChampionDataContext from "../../top-level/ChampionDataContext.react";
import {getChampionPortraitURL} from "../../top-level/ChampionUtils";
import {useContext} from 'react';

type Props = {
  challenge: {},
  currentSummoner: String,
  shownGamers: Array<String>,
  trivia: any,
};

const TOP_LEVEL_CHALLENGES = {
  CRYSTAL: "Total Mastery Points",
  IMAGINATION: "Imagination (Category)",
  EXPERTISE: "Expertise (Category)",
  VETERANCY: "Veterancy (Category)",
  TEAMWORK: "Teamwork (Category)",
  COLLECTION: "Collection (Category)",
}

const TIER_ICONS = {
  none: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_none.png",
  iron: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_iron.png",
  bronze: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_bronze.png",
  silver: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_silver.png",
  gold: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_gold.png",
  platinum: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_platinum.png",
  diamond: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_diamond.png",
  master: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_master.png",
  grandmaster: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_grandmaster.png",
  challenger: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_challenger.png",
};

function LeaderboardGridItem(props: Props): React.Element {
  const { staticSummonerData, masteryData } = useContext(ChallengesDataContext);
  const { version } = useContext(ChampionDataContext);

  const leaderboard = props.challenge.leaderboard;

  const sortedLeaderboard = Object.keys(leaderboard).sort((a,b) => {
    if (leaderboard[b] == null) {
      return -1;
    } else if (leaderboard[a] == null) {
      return 1;
    }
    return leaderboard[b].value - leaderboard[a].value;
  });

  const maxLevel = leaderboard[sortedLeaderboard[0]]?.level ?? "iron";
  const regex = /\/challenges-images\/(\d+)-IRON.png/;
  const challengeID = (props.challenge.icon ?? "").match(regex)[1];
  const challengeTitle = getCorretTitle(props.challenge.title);

  return (
    <div className='LeaderboardChallengeItem'>
      <div className='LeaderboardChallengeItemTitleContainer'>
        <div className='LeaderboardChallengeItemIconContainer'>
          {challengeID < 10 ? null : 
            <img 
              className={'LeaderboardChallengeItemIcon ' + String(maxLevel).toUpperCase() + ' LeaderboardChallengeItemIconBoxshadow'}  
              src={props.challenge.icon?.replace("IRON",  String(maxLevel).toUpperCase())} alt=''
            />}
        </div>
        <div className='LeaderboardChallengeItemTitleBox'>
          <div className='LeaderboardChallengeItemTitle'>{challengeTitle} </div>
          <div className='LeaderboardChallengeItemDescription'>{props.challenge.description} </div>
        </div>
      </div>
      <div className='LeaderboardChallengeItemLeaderListHeaderRow'>
        <div className='LeaderboardChallengeItemLeaderListCol1'>Position</div>
        <div className='LeaderboardChallengeItemLeaderListCol2'>Summoner</div>
        <div className='LeaderboardChallengeItemLeaderListCol3'>Points</div>
      </div>
      <div className='LeaderboardChallengeItemLeaderList'>
        {sortedLeaderboard.map((playerKey, i) => {
          if (props.shownGamers && props.shownGamers.indexOf(playerKey) === -1) {
            return null;
          }
          const player = leaderboard[playerKey];
          const currLevel = player?.level ?? "none";
          const randomChamp = masteryData[Math.floor(Math.random() * masteryData.length)];
          return (
            <div 
            className={
              "LeaderboardChallengeItemLeaderListItem " + String(currLevel).toUpperCase() + ' ' + (!props.trivia && playerKey.toLowerCase() === props.currentSummoner.toLowerCase() ? "CURRENTSUMMONER" : "OTHERSUMMONER")
            } 
            key={i}> 
            <div className='LeaderboardChallengeItemLeaderListCol1'>{i + 1}.</div>
            <div className='LeaderboardChallengeItemLeaderListCol2'>
              <img className='LeaderboardChallengeItemLeaderListItemSummonerIcon' src={props.trivia ? getChampionPortraitURL(randomChamp.name, version) : staticSummonerData.all_participants[playerKey].summoner_icon_url} alt=''/>
              {props.trivia ? randomChamp.name : playerKey}
            </div>
            <div className='LeaderboardChallengeItemLeaderListCol3'>
              <img className='LeaderboardChallengeItemLeaderListItemValueIcon' src={TIER_ICONS[currLevel.toLowerCase()]} alt='' title={currLevel[0].toUpperCase() + currLevel.slice(1)}/>
              {Number(player?.value ?? 0).toLocaleString()}
            </div>
          </div>
          );
        })} 
      </div>
    </div>
  );
}

function getCorretTitle(title: string): string {
  if (TOP_LEVEL_CHALLENGES.hasOwnProperty(title)) {
    return TOP_LEVEL_CHALLENGES[title];
  }
  return title;
}


export default LeaderboardGridItem;
