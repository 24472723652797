import VirtuosoHeaderCard from './VirtuosoHeaderCard.react';
import VirtuosoChallenge from './VirtuosoChallenge.react';
import React from 'react';
import {useContext} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

const VIRTUOSO_CLASSES = ['tank', 'assassin', 'mage', 'fighter', 'support', 'marksman'];

function VirtuosoChallengeView(props: Props): React.Element {
  const { challengesData } = useContext(ChallengesDataContext);

  return (
    <div className={"challengeViewContainer"}>
      <VirtuosoHeaderCard />
      <div className='virtuosoContainer'>
        {Object.keys(challengesData.virtuoso).map(challenge => (
          <VirtuosoChallenge
            key={challenge}       
            title={challenge.replace(/_/g, ' ')
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ').replace("Legacy", "(Legacy)")} 
            currentLevel={challengesData.virtuoso[challenge].current_level} 
            nextLevel={challengesData.virtuoso[challenge].next_level}
            description={challengesData.virtuoso[challenge].description}
            icon={challengesData.virtuoso[challenge].current_level_icon} 
            class={extractClass(challenge)} />
        ))}
      </div>
    </div>
  );
}

function extractClass(str): string {
  for (const category of VIRTUOSO_CLASSES) {
    if (str.includes(category)) {
      return category.charAt(0).toUpperCase() + category.slice(1);
    }
  }
  return null;
  
}

export default VirtuosoChallengeView;
