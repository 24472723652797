import React from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = {
  onClickSelector: () => void,
  filterSelectors: {mode: ?String, category: ?String, sort: ?String, shownGamers: Array<String>},
};

const CATEGORIES = [
  {
    name: "Expertise",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_expertise.svg",
  },
  {
    name: "Teamwork",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_teamwork.svg",
  },
  {
    name: "Imagination",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_imagination.svg",
  },
  {
    name: "Veterancy",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_veterancy.svg",
  },
  {
    name: "Collection",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_collection.svg",
  },
  {
    name: "Legacy",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon_category_legacy.svg",
  },
];

const MODES = [
  {
    name: "Summoner's Rift",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-classic.svg",
  },
  {
    name: "ARAM",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-aram.svg",
  },
  {
    name: "Co-op vs. AI",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/eternals-personal-best.svg",
  },
];

const SORTS = [
  {
    name: "Highest Ranked",
    icon: (
      <svg style={{ height: 20, width: 20, marginRight: 4}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>),
    // from heroicons.com
  },
  {
    name: "Lowest Ranked",
    icon: (
      <svg style={{ height: 20, width: 20, marginRight: 4}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
  },
];


function LeaderboardLeftSidebar(props: Props): React.Element {
  const selectors = props.filterSelectors;
  const { staticSummonerData } = useContext(ChallengesDataContext);
  const gamers = Object.keys(staticSummonerData?.all_participants ?? {});

  return (
    <div className={"LeaderboardLeftSidebar"}>
      <div className={"LeaderboardLeftSidebarSelectors"}>
        <div className={"LeaderboardLeftSidebarCategory"}>
          <p className={"LeaderboardLeftSidebarCategoryHeader"}>
            Category
          </p>
          {CATEGORIES.map(f => (
            <div key={f.name}
              className={'LeaderboardLeftSidebarSingleSelector' + (f.name.toLowerCase() === selectors.category?.toLowerCase() ? " SELECTED" : "")}
              onClick={() => props.onClickSelector({category: handleClickOnSelector(selectors.category, f.name), mode: selectors.mode, sort: selectors.sort, shownGamers: selectors.shownGamers, trivia: selectors.trivia})}>
              <img className='LeaderboardLeftSidebarSelectorIcon' src={f.url} alt=''/>
              {f.name}
            </div> 
          ))}
        </div>
        <div className={"LeaderboardLeftSidebarCategory"}>
          <p className={"LeaderboardLeftSidebarCategoryHeader"}>
            Game Mode
          </p>
          {MODES.map(f => (
            <div key={f.name}
              className={'LeaderboardLeftSidebarSingleSelector' + (f.name.toLowerCase() === selectors.mode?.toLowerCase() ? " SELECTED" : "")} 
              onClick={() => props.onClickSelector({mode: handleClickOnSelector(selectors.mode, f.name), category: selectors.category, sort: selectors.sort, shownGamers: selectors.shownGamers, trivia: selectors.trivia})}>
              <img className='LeaderboardLeftSidebarSelectorIconSVG' src={f.url} alt=''/>
              {f.name}
            </div> 
          ))}
        </div>
        <div className={"LeaderboardLeftSidebarCategory"}>
          <p className={"LeaderboardLeftSidebarCategoryHeader"}>
            Sort By
          </p>
          {SORTS.map(f => (
            <div key={f.name}
            className={'LeaderboardLeftSidebarSingleSelector' + (f.name.toLowerCase() === selectors.sort?.toLowerCase() ? " SELECTED" : "")} 
            onClick={() => props.onClickSelector({mode: selectors.mode, category: selectors.category, sort: handleClickOnSelector(selectors.sort, f.name), shownGamers: selectors.shownGamers, trivia: selectors.trivia})}>
            {f.icon}
              {f.name}
            </div> 
          ))}
        </div>
        {gamers == null ? null : 
          <div className={"LeaderboardLeftSidebarCategory"}>
            <p className={"LeaderboardLeftSidebarCategoryHeader"}>
              Shown Gamers
            </p>
            {gamers.map(f => (
              <div key={f} className={'LeaderboardLeftSidebarSingleSelector'} >
                <FormGroup className={"LeaderboardLeftSidebarCheckbox"}>
                  <FormControlLabel control={
                    <Checkbox
                      size="small"
                      checked={(selectors?.shownGamers ?? []).indexOf(f) !== -1}
                      onChange={checked => {
                        props.onClickSelector(
                          {
                            mode: selectors.mode, 
                            category: selectors.category, 
                            sort: selectors.sort, 
                            shownGamers: checked.target.checked === true ? [...selectors.shownGamers, f] : (selectors?.shownGamers ?? []).filter(gamer => gamer !== f),
                            trivia: selectors.trivia
                          }
                        )
                      }}
                      style ={{
                        color: "#c8c8c8",
                        padding: "0 10px 0 6px",
                        fontSize: "1rem",
                      }}
                    />}
                  label={f} />
                </FormGroup>
              </div> 
            ))}
          </div>
        }
        <div className={"LeaderboardLeftSidebarCategory"}>
          <p className={"LeaderboardLeftSidebarCategoryHeader"}>
            For Fun
          </p>
          <div className={'LeaderboardLeftSidebarSingleSelector'} >
            <FormGroup className={"LeaderboardLeftSidebarCheckbox"}>
              <FormControlLabel control={
                <Checkbox
                  size="small"
                  checked={selectors.trivia}
                  onChange={checked => {
                    props.onClickSelector(
                      {
                        mode: selectors.mode, 
                        category: selectors.category, 
                        sort: selectors.sort, 
                        shownGamers: selectors.shownGamers,
                        trivia: !selectors.trivia
                      }
                    )
                  }}
                  style ={{
                    color: "#c8c8c8",
                    padding: "0 10px 0 6px",
                    fontSize: "1rem",
                  }}
                />}
              label={"Trivia Mode"} />
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

function handleClickOnSelector(existingSelector: ?String, clickedSelector: ?String): ?String {
  if (existingSelector == null) {
    return clickedSelector;
  } else if (existingSelector.toLowerCase() === clickedSelector.toLowerCase()) {
    return null;
  } else {
    return clickedSelector;
  }
}

export default LeaderboardLeftSidebar;

