
const GRADE_ICONS = {
  A: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/grade-a.svg",
  B: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/grade-b.svg",
  C: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/grade-c.svg",
  S: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/grade-s.svg",
  completedGrade: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/completed-grade-overlay.svg",
};

function Grades(props: {rawGrades: any}): React.Element {
  const rawGrades = props.rawGrades
  const gradeComponents = [];
  Object.entries(rawGrades).forEach(
    ([grade, { required, left }]) => {
        [...Array(required)].forEach((_, index) => (
          gradeComponents.push(
            <div key={index} className="gradeContainer">
              <img 
                src={GRADE_ICONS[grade.replace(/[^a-zA-Z]/g, '')]} 
                className={"grade"} 
                alt='w'
              />
              {
                index < required - left 
                ? <div class="gradeOverlay">
                    <img 
                      src={GRADE_ICONS.completedGrade} 
                      className={"gradeOverlay"} 
                      alt='w'
                    />
                  </div>
                : null
              }
            </div>
          )
        )
      )
    }
  )
  return (
    <div className={'gradesContainer'}>
      {gradeComponents}
    </div>
  );
}

export default Grades;
