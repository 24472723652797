import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const MODES = [
  {
    name: "Summoner's Rift",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-classic.svg",
  },
  {
    name: "ARAM",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/icon-map-aram.svg",
  },
  {
    name: "Co-Op vs. AI",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/eternals-personal-best.svg",
  },
];


function LowHangingFruitChallengeCard(props) {
    const currentLevel = props.currentLevel == null ? { level: "None", value: 0 } : props.currentLevel;
    const nextLevel = props.nextLevel;
  
    const currentLevelFormatted =
      currentLevel.level[0] + currentLevel.level.substring(1).toLowerCase();
  
    const nextLevelFormatted = nextLevel.level[0] + nextLevel.level.substring(1).toLowerCase();
    const totalPoints = props.totalPoints;
    const icon = (Math.floor(Math.random() * 500) === 250) ? 'https://ddragon.leagueoflegends.com/cdn/13.18.1/img/champion/Velkoz.png' : props.iconURL;
  
    return (
      <Card className={"LHFCard " + currentLevel.level.toUpperCase() + ' ratedBorder'}>
        {
          props.queue && (
            <div className='LHFCornerIcon'>
              <img src={MODES.find(m => m.name === props.queue).url} alt='' />
            </div>
          )
        }
        {currentLevel.level.toLowerCase() !== "none" ? <div className='LHFChallengeIconContainer'>
          <img className={'LHFChallengeIcon ' + currentLevel.level.toUpperCase() + ' ratedBoxshadow'} src={icon} alt='' />
        </div> : null}
        <CardContent className='LHFCardInner'>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" gutterBottom display='block' component="div">
            {props.description}
          </Typography>
          <Typography variant="h6" component="div">
            <div>
              Current Level: <Box className={currentLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{currentLevelFormatted} ({Number(currentLevel.value).toLocaleString()})</Box>
            </div>
          </Typography>
          <Typography variant="h6" component="div">
            <div>
              To advance to <Box className={nextLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{nextLevelFormatted}: {Number((nextLevel.value - currentLevel.value)).toLocaleString()}</Box>
            </div>
          </Typography>
          <Typography variant="subtitle2" component="div">
            <div>
              (+{totalPoints} points)
            </div>
          </Typography>
        </CardContent>
      </Card>
    );
  }

export default LowHangingFruitChallengeCard;
