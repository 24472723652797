import ChallengeCard from "./ChallengeCard.react";
import React from 'react';
import {useMemo, useContext} from 'react';
import ChallengeRenderer from "./ChallengeRenderer.react";
import {useNavigate} from "react-router-dom";
import ChallengesDataContext from '../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
  challenge: string,
};

const CHALLENGES = ['catch-em-all', 'jack-of-all-champs', 'master-yourself', 'x-diff', 'low-hanging-fruit', 'leaderboard', 'master-the-enemy', 'virtuoso'];

function MainChallengeView(props: Props): React.Element {
  const { challengesData } = useContext(ChallengesDataContext);
  const navigate = useNavigate();

  const selectedChallenge = useMemo(
    () =>
      ((challengesData.length === 0
      || props.challenge === null
      || !CHALLENGES.includes(props.challenge))
      ? null
      : props.challenge),
    [props.challenge, challengesData]);
  const navigateToChallenge = (challenge) => {
    navigate(
      "/"
      + props.lookupTarget.region
      + "/"
      + props.lookupTarget.summoner
      + "/"
      + challenge
    );
  };

  if (selectedChallenge === null) {
    // Return challenges card selector
    return (
      <div className={'challengeSelectionView'}>
        <ChallengeCard
            onClick={() => navigateToChallenge("leaderboard")}
            label={"Teemo Fanclub Leaderboards"}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("catch-em-all")}
          label={"Catch 'Em All"}
          currentLevel={challengesData.catch_em_All.current_level?.level}
          currentLevelIconURL={challengesData.catch_em_All.current_level_icon}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("master-yourself")}
          label={"Master Yourself"}
          currentLevel={challengesData.master_yourself.current_level?.level}
          currentLevelIconURL={challengesData.master_yourself.current_level_icon}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("master-the-enemy")}
          label={"Master The Enemy"}
          currentLevel={challengesData.master_the_enemy.current_level?.level}
          currentLevelIconURL={challengesData.master_the_enemy.current_level_icon}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("x-diff")}
          label={"Diff Challenges"}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("low-hanging-fruit")}
          label={"Low Hanging Fruit Challenges"}
        />
        <ChallengeCard
          onClick={() => navigateToChallenge("virtuoso")}
          label={"Virtuoso Challenges"}
        />

      </div>
    );
  }

  return <ChallengeRenderer lookupTarget={props.lookupTarget} challenge={selectedChallenge} />;
}

export default MainChallengeView;
