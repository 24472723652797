import React from 'react';
import {useMemo, useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableSortLabel
} from '../mastery-list/StylizedTableComponentsUtil';
import {getChampionPortraitURL} from "../../top-level/ChampionUtils";
import MasteryRowProgress from '../mastery-list/MasteryRowProgress.react';
import MasteryRowGradeProgress from '../mastery-list/MasteryRowGradeProgress.react';
import ChampionDataContext from '../../top-level/ChampionDataContext.react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import Popover from '@mui/material/Popover';

type Props = {
  masteryData: Array<{
    champion_id: number,
    name: string,
    points: string,
  }>,
  summoner: string,
  amountToShow: Number,
}

function MasterTheEnemyMasteryList(props: Props): React.Element {
  const { masteryData } = useContext(ChallengesDataContext);
  const { version } = useContext(ChampionDataContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [content, setContent] = React.useState("");

  const handlePopoverOpen = (event: React.MouseEven, itemContent) => {
    setAnchorEl(event.currentTarget);
    setContent(itemContent);

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setContent(null);
  };

  const open = Boolean(anchorEl);

  const level9Champions = useMemo(
    () => masteryData.filter(champion => champion.level <= 9).slice(0, props.amountToShow),
    [props.amountToShow, masteryData],
  );

  const sortedRows = useMemo(
    () =>
      level9Champions
      .map(
        row =>
          ({
            id: row.champion_id,
            name: row.name,
            points: row.points,
            points_from_last_level: row.points_since_last_level,
            points_to_next_level: Math.max(0, row.points_until_next_level),
            level: row.level,
            grades: row.next_season_milestone.grades,
            current_marks: row.tokens_earned,
            marks_required: row.mark_required_for_next_level,
          })
      ),
    [level9Champions],
  );

  return (
    <div className="masteryTable">
      <>
        <Title summoner={props.summoner}  />
        <TableContainer className='masteryList'>
          <Table sx={{ color: "white", minWidth: 700, border: "1px solid white" }} aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell/>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Level</StyledTableCell>
                <StyledTableCell sortDirection={"desc"}>
                  <StyledTableSortLabel
                    direction={"desc"}
                    active={false}>
                    Mastery
                  </StyledTableSortLabel>
                </StyledTableCell>
                <StyledTableCell>Marks</StyledTableCell>
                <StyledTableCell>Grades</StyledTableCell>
                <StyledTableCell>Progress</StyledTableCell>
                <StyledTableCell>Points to next level</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows
                .map((row) =>  (
                <StyledTableRow key={row.name}>
                  <StyledTableCell style={{width: 24}}>
                    <div className={"masteryListChampionPortrait"}>
                      <img 
                        src={getChampionPortraitURL(row.name, version)} 
                        className={"masteryListChampionPortrait"} 
                        alt={row.name + "'s champion portrait"} 
                      />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" style={{color: "white"}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{row.level}</StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{Number(row.points).toLocaleString()}</StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{row.current_marks}/{row.marks_required}</StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>
                    <div
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(e) => handlePopoverOpen(e, <MasteryRowGradeProgress grades={row.grades} name={row.name}/>)}
                      onMouseLeave={handlePopoverClose}
                    >
                      {5 - Object.values(row.grades).reduce((sum, grade) => sum + grade.left, 0)}/{Object.values(row.grades).reduce((sum, grade) => sum + grade.required, 0)}
                    </div>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        '& .MuiPaper-root': {
                          backgroundColor: '#0e141b',
                          color: 'white',
                          padding: '0 4px 0 4px',
                          borderRadius: '10px',
                          boxShadow: '2px 2px 2px 2px black',

                        },
                        pointerEvents: 'none' 
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                    {content}
                    </Popover>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MasteryRowProgress 
                      level={4} 
                      points={row.points} 
                      progressPCT={Number(row.points_from_last_level) / (Number(row.points_to_next_level) + Number(row.points_from_last_level))} 
                      points_to_next={row.points_to_next_level} 
                      points_from_last={row.points_from_last_level}/>
                  </StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{row.points_to_next_level}</StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
}

function Title(props: {summoner: string}): React.Element {
  return (
    <div className="tableTitle">
      <h2>
        {"Candidate champions to upgrade"}
      </h2>
    </div>
  );
}

export default MasterTheEnemyMasteryList;
