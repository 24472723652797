import React from 'react';
import LowHangingFruitChallengeCard from './LowHangingFruitChallengeCard.react';

const GAINED_POINTS = { "none": 0, "bronze": 5, "silver": 5, "gold": 10, "platinum": 15, "diamond": 20, "master": 40 };

function LowHangingFruitChallenges(props) {
    const challenges = props.challenges || [];
    const sortBy = props.sortBy;
    return (
        <div className={"LHFCardContainer"}>
            {
                getSortedChallenges(challenges, sortBy)
                .slice(0, 12)
                .map(challenge =>
                    <LowHangingFruitChallengeCard
                    title={challenge.name}
                    currentLevel={challenge.currentLevel}
                    nextLevel={challenge.nextLevel}
                    description={challenge.description}
                    percentile={challenge.percentile}
                    iconURL={challenge.iconURL}
                    key={challenge.name}
                    totalPoints={challenge.totalPoints}
                    queue={challenge.queue} />
            )}
        </div>
    );
};

const getSortedChallenges = (challenges, sortBy) => {
    if (!challenges) return [];
    if (!sortBy) return challenges;
    
    return [...challenges].sort((a, b) => {
        switch (sortBy) {
            case 'Challenge Level':
                console.log("sorting by challenge level");    
                return getSortedByChallengeLevel(a, b);
            case 'Total Points':
                console.log("sorting by total points");
                return b.totalPoints- a.totalPoints;
            case 'percentile':
                console.log("sorting by percentile");
                return (b.percentile || 0) - (a.percentile || 0);
            default:
                console.log("sorting by default");
                return 0;
        }
    });
};

const getSortedByChallengeLevel = (a, b) => {
    const currentLevelA = a.currentLevel == null ? { level: "None", value: 0 } : a.currentLevel;
    const currentLevelB = b.currentLevel == null ? { level: "None", value: 0 } : b.currentLevel;
    return GAINED_POINTS[currentLevelB.level.toLowerCase()] - GAINED_POINTS[currentLevelA.level.toLowerCase()];
};

export default LowHangingFruitChallenges;
