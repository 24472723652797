import React from 'react';

type Props = {
  progressPCT: number,
  points: Number,
  level: number,
  colorClass: String,
  points_to_next?: any,
  points_from_last?: any,
};

const REQUIREMENTS = [1800, 6000, 12600, 21600];

function MasteryRowProgress(props: Props): React.Element {
  if (props.progressPCT == null) {
    return null;
  }
  const innerColor = props.colorClass ?? "";
  let title;
  if (!!props.points_from_last && !!props.points_to_next) {
    title = props.level == null ? '' : props.points_from_last + "/" + (Number(props.points_from_last) + Number(props.points_to_next));  
  } else{ 
    title = props.level == null ? '' : props.points + "/" + REQUIREMENTS[props.level - 1]; 
  }

  return (
    <div className={"progressContainer"} title={title}>
      <div className={"progressBar"}>
        <div className={"progressBarInner " + innerColor} style={{'width': (Math.min(props.progressPCT, 1) * 100) + '%'}}/>
      </div>
    </div>
  );
}

export default MasteryRowProgress;
