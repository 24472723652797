import LowHangingFruitHeaderCard from './LowHangingFruitHeaderCard.react';
import LowHangingFruitChallenges from './LowHangingFruitChallenges.react';
import LowHangingFruitFilter from './LowHangingFruitFilter.react';
import React from 'react';

import { useContext } from 'react';
import { useState, } from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

function LowHangingFruitChallengeView() {
  const { lowHangingFruit } = useContext(ChallengesDataContext);

  const fruitData = Object.keys(lowHangingFruit).map(key =>
  ({
    name: lowHangingFruit[key].challenge_name,
    currentLevel: lowHangingFruit[key].current_level,
    description: lowHangingFruit[key].description,
    nextLevel: lowHangingFruit[key].next_level,
    percentile: lowHangingFruit[key].percentile,
    iconURL: lowHangingFruit[key].current_level_icon,
    totalPoints: lowHangingFruit[key].total_points,
    queue: lowHangingFruit[key].queue,
  }));

  const [filterSelectors, setFilterSelectors] = useState({ mode: 'All', sort: "Challenge Level" });

  return (
    <div className={"challengeViewContainer"}>
      <LowHangingFruitHeaderCard />
      <LowHangingFruitFilter filterSelectors={filterSelectors} onClickSelector={setFilterSelectors} />
      <LowHangingFruitChallenges challenges={applyFilters(fruitData, filterSelectors)} sortBy={filterSelectors.sort} />
    </div>
  );
};

function applyFilters(challenges, filterSelectors) {
  return challenges.filter(c => {
    if (filterSelectors.mode != null && filterSelectors.mode !== 'All' && c.queue?.toLowerCase() != null && c.queue.toLowerCase() !== filterSelectors.mode.toLowerCase()) {
      return false;
    }
    return true;
  });
}

export default LowHangingFruitChallengeView;
