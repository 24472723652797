import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useMemo} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {getChampionPortraitURL} from "../../top-level/ChampionUtils";
import ChampionDataContext from '../../top-level/ChampionDataContext.react';
import Grades from "../Grade.react";

type Props = {
  title: String,
  description?: string,
  currentLevel: {level: string, value: number}, 
  nextLevel: {level: string, value: number},
  icon?:String,
  class: String,
};

function VirtuosoHeaderCard(props: Props): React.Element {
  const { masteryData } = useContext(ChallengesDataContext);
  const { version } = useContext(ChampionDataContext);

  const currentLevel = props.currentLevel == null ? {level: "None", value: 0} : props.currentLevel;
  const currentLevelFormatted =
  currentLevel.level[0] + currentLevel.level.substring(1).toLowerCase();
  const nextLevelFormatted = props.nextLevel.level[0] + props.nextLevel.level.substring(1).toLowerCase();
  const champClass = props.class;
  const isLegacy = props.title.includes('Legacy');

  const top5Champions = useMemo(() => 
      masteryData
        .filter(champion => champion.classes.includes(champClass))
        .filter(champion => Number(champion.level) <= (isLegacy ? 6 : 9))
        .sort(compareChamps)
        .slice(0, 5),
    [masteryData, champClass, isLegacy],
  );

  return (
    <Card className={"virtuosoCard " + currentLevel.level.toUpperCase() + ' ratedBorder'}>
      {currentLevel.level.toLowerCase() !== "none" ? 
        <div className='virtuosoChallengeIconContainer'>
          <img className={'LHFChallengeIcon ' + currentLevel.level.toUpperCase() + ' ratedBoxshadow'}  src={props.icon} alt=''/>
        </div>
        : null}
    <CardContent className='LHFCardInner'>
    <Typography gutterBottom variant="h5" component="div">
        {props.title}
      </Typography>
      <Typography variant="body2" gutterBottom display='block' component="div">
        {props.description}
      </Typography>
      <Typography variant="h6" component="div" display='block' sx={{marginBottom: 8}}>
        <div>
          Current Level: <Box className={currentLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{currentLevelFormatted}</Box> | To <Box className={props.nextLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{nextLevelFormatted}</Box>: {Number(currentLevel.value).toLocaleString()}/{Number(props.nextLevel.value).toLocaleString()}
        </div>
      </Typography>
      <div className='virtuosoChampSectionContainer'>{top5Champions.map(champ => <VirtuosoChampion key={champ.name} champ={champ} version={version}/>)}</div>
      </CardContent>
    </Card>
  );
}

function compareChamps(a, b): number {
  const aPoints = a.points_until_next_level
  const bPoints = b.points_until_next_level
  const aGrades = Object.values(a.next_season_milestone.grades).reduce((sum, grade) => sum + grade.left, 0)
  const bGrades = Object.values(b.next_season_milestone.grades).reduce((sum, grade) => sum + grade.left, 0)
  const aMarks = a.marks_required_for_next_level - a.tokens_earned
  const bMarks = b.marks_required_for_next_level - b.tokens_earned
  if (a.level !== b.level) {
    return b.level - a.level;
  } else if (aPoints > 0 || bPoints > 0) {
    return aPoints - bPoints;
  } else if (aGrades > 0 || bGrades > 0) {
    return aGrades - bGrades;
  } else {
    return aMarks - bMarks;
  }
}

function VirtuosoChampion(props: {champ: any, version: any}): React.Element {
  const champ = props.champ
  const url = getChampionPortraitURL(champ.name, props.version)

  return (
    <div className={'virtuosoChampContainer'}>
      <div className={'virtuosoOverallChampContainer'}>
        <div style={{marginBottom: 8}}>Level {champ.level}</div>
        <div className={'virtuosoInnerChampContainer'}>
          <div className={'virtuosoChampPortrait'}>
            <img 
              src={url} 
              className={"virtuosoChampPortrait"} 
              alt={props.name + "'s champion portrait"} 
            />
          </div>
          <Typography variant="subtitle2" component="div" sx={{fontSize: 13}}>
            <div className={'virtuosoChampDetails'}>
              <div className={'virtuosoMasteryContainer'}>
                <img 
                  src={"https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/generic-mastery-icon.svg"} 
                  className={"virtuosoMasteryIcon"} 
                  alt='w'
                />
                {Number(Math.min(champ.points_since_last_level, (Number(champ.points_until_next_level)+Number(champ.points_since_last_level)))).toLocaleString()}/{(Number(champ.points_until_next_level)+Number(champ.points_since_last_level)).toLocaleString()} pts
                </div>
              <Grades rawGrades={champ.next_season_milestone.grades}/>
              <div className={'virtuosoMarksContainer'}>
                <img 
                  src={"https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/mark-token.svg"} 
                  className={"virtuosoMarksIcon"} 
                  alt='w'
                />
                {champ.tokens_earned}/{champ.mark_required_for_next_level}
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </div> 
  );
}

export default VirtuosoHeaderCard;
